import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressComponent } from './progress/progress.component';
import { QuestionsLoopDirective } from './questions-loop.directive';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    ProgressComponent, SpinnerComponent, QuestionsLoopDirective
  ],
  declarations: [ProgressComponent, SpinnerComponent, QuestionsLoopDirective]
})
export class SharedModule { }
