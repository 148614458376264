import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {URLS} from '../assets/config';

export const sortByOrder = (a, b) => a.sortOrder - b.sortOrder;

export interface ISurveyOption {
  id: number;
  name: string;
  sortOrder: number;
  inactive: boolean;
  nextQuestionId: number;
  selected: boolean;
}
export interface ISurveyQuestion {
  id: number;
  number: number;
  name: string;
  sortOrder: number;
  inactive: boolean;
  isRequired: boolean;
  questionOptions: ISurveyOption[];
  questionTypeId: number;
  instruction: string;
  parentQuestionId: number;
  nextQuestionId: number;
  previousQuestionId: number;
  studentSurveyAnswers: {};
}
@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  private guid: string;
  public number = 0;
  // Local dev
  //private apiUrl = 'http://localhost:5002/api';
  private apiUrl = URLS.apiBackend;
  constructor(private http: HttpClient) { }

  public getApiUrl() {
    return this.http.get('config.json').pipe(
      tap((data: any) => {
        this.apiUrl = data.apiUrl;
      })
    );
  }

  public getSurveyDetails(guid: string): Observable<ISurveyQuestion[]> {
    if (typeof guid === 'string') {
      this.guid = guid;
    }
    // Check if the apiUrl exists and begins with http, else use the "config.json" approach
    if (!this.apiUrl || !this.apiUrl.startsWith('http')) {
      return this.getApiUrl().pipe(
        switchMap(this.getSurveyDetails.bind(this))
      );
    }
    return this.http.get(`${this.apiUrl}/questions/${this.guid}`)
      .pipe(
        map((questions: ISurveyQuestion[]) => {
          return questions.map((question, index) => {
            if (!question.parentQuestionId) {
              this.number++;
            }

            return {
              ...question,
              number: question.parentQuestionId ? 0 : this.number,
              questionOptions: question.questionOptions.sort(sortByOrder)
            };
          }).sort(sortByOrder);
        })
      );
  }

  public getQuestionTypes() {
    return this.http.get(
      `${this.apiUrl}/questiontypes`
    );
  }

  public saveSurveyAnswer(question: ISurveyQuestion | { id, questionTypeId}, value) {
    if (!this.apiUrl) {
      return timer(100).pipe(
        switchMap(this.saveSurveyAnswer.bind(this, question, value))
      );
    }
    let optionId = null;
    if (question.questionTypeId === 1) {
      optionId = value;
    } else if (question.questionTypeId === 2) {
      optionId = Object.keys(value)[0];
    } else if(question.questionTypeId === 4) {
      optionId = value;
    }
    let valueStr = null;
    if (question.questionTypeId === 2) {
      valueStr = value[optionId].toString();
    } else if (question.questionTypeId === 3) {
      valueStr = value;
    } 
    return this.saveSurveyPostCall(question, valueStr, optionId);
  }

  public saveSurveyPostCall (question, valueStr, optionId) {
    return this.http.post(
      `${this.apiUrl}/StudentSurveyAnswer?guid=${this.guid}`, {
      questionId: question.id,
      answerText: valueStr,
      questionOptionId: optionId
      }
    );
  }

  public setSurveyComplate() {
    return this.http.post(
      `${this.apiUrl}/studentsurveyemail/updatesurveystatus?guid=${this.guid}&completed=true`, {}
    );
  }
}
