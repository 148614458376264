import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import get from 'lodash-es/get';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ISurveyQuestion, SurveyService } from './survey.service';
import { getParams } from './utils';

@Component({
  selector: 'c2-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.scss'],
})
export class AppComponent {
  total: any;
  controller: any = {
    isLast: () => {
      return false;
    },
    isFirst: () => {
      return true;
    },
  };
  questions: ISurveyQuestion[] = [];
  surveyForm = new FormGroup({
    questions: new FormArray([]),
  });
  subsurveyForm = new FormGroup({
    questions: new FormArray([]),
  });
  loading: boolean;
  phase = environment.phase;
  warning = false;
  error: HttpErrorResponse;
  locked: boolean;
  subQuestions: ISurveyQuestion[] = [];
  restOfQuestions: ISurveyQuestion[] = [];
  allSubQuestionAnswered = false;

  get questionsField(): FormArray {
    return <FormArray>this.surveyForm.get('questions');
  }
  get subQuestionsField(): FormArray {
    return <FormArray>this.subsurveyForm.get('questions');
  }

  get nextDisabled() {
    if (!this.controller.index) {
      this.controller.index = 0;
    }
    const controlValue = get(this.questionsField, [
      'controls',
      this.controller.index,
      'value',
    ]);

    const isValid = get(this.questionsField, [
      'controls',
      this.controller.index,
      'status',
    ]);
    if (isValid === 'VALID') {
      return false;
    }

    let shouldDisable = true;
    if (typeof controlValue === 'object') {
      // Special case for checkbox type questions..
      if (!!controlValue.isOtherOptionsSelected) {
        shouldDisable = false;
      } else {
        shouldDisable = true;
      }
    } else {
       shouldDisable = !controlValue;
    }
    const questiontype = get(this.questions[this.controller.index], 'questionTypeId');
    const qtype5 = this.questions.filter(x => x.questionTypeId ===5)[0];
    if( questiontype === 5 && Object.keys(qtype5.studentSurveyAnswers).length === 6) {
      return false;
    }
    const retVal = get(this.questions[this.controller.index], 'isRequired')
      ? shouldDisable
      : false;
    return retVal;
  }

  constructor(private surveyService: SurveyService) {
    const queryParams = getParams(window.location.search);
    this.loading = true;
    this.surveyService.getSurveyDetails(queryParams.guid).subscribe(
      questions => {
        const queryParams1 = getParams(window.location.search);
        if (queryParams1.qId && queryParams1.aId) {
          if (queryParams1.aId === '1159' || queryParams1.aId === '1160') {
            var theRemovedElement = questions.shift();
          }
        }

        this.subQuestions = questions.filter(x => x.number === 0);
        this.restOfQuestions = questions.filter(x => x.number !== 0);

        this.loading = false;
        this.questions = questions;
       // console.log(this.restOfQuestions);
       // console.log(this.subQuestions);
        this.total = this.restOfQuestions.length;
        if (this.restOfQuestions && this.restOfQuestions.length > 0) {
          this.addQuestionsToForm(this.restOfQuestions);
        }

        if (this.subQuestions && this.subQuestions.length > 0) {
          this.addsubQuestionsToForm(this.subQuestions);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status === 410 && err.statusText === 'Gone') {
          console.log('Gone Gone Goneeee......');
        } else if (err.status === 403) {
          this.warning = true;
        } else if (err.status === 423) {
          this.locked = true;
        } else {
          this.warning = true;
          this.error = err;
        }
        this.loading = false;
      }
    );
    if (queryParams.qId && queryParams.aId) {
      this.surveyService
        .saveSurveyAnswer(
          { id: queryParams.qId, questionTypeId: 1 },
          queryParams.aId
        )
        .subscribe();
    }
  }

  @HostListener('window:keydown', ['$event'])
  doSomething($event) {
    if (
      $event.keyCode === 39 &&
      !this.controller.isLast() &&
      !this.nextDisabled
    ) {
      this.controller.next();
    } else if ($event.keyCode === 37 && !this.controller.isFirst()) {
      this.controller.prev();
    }
  }

  setController(c) {
    this.controller = c;
  }

  onRadioChange() {
    return this.phase === 2 && !this.controller.isLast()
      ? setTimeout(() => this.controller.next(), 250)
      : null;
  }

  subQuestionAnswered(obj) {
    let question = this.subQuestions.filter(x => x.id === obj["questionId"])[0];
    const all = obj["all"];

    const ques = obj["mainQuestion"];
    this.restOfQuestions.forEach(x => {
      if(x.id=== ques.id ){
        x.studentSurveyAnswers = all;
      }
    })
    this.surveyService
      .saveSurveyPostCall(question, null, obj["selectedOptionId"]).subscribe(() => {
        if (Object.keys(all).length === 6) {
          this.allSubQuestionAnswered = true;
          setTimeout(() => this.controller.next(), 250);
        }
      });
  }

  addQuestionsToForm(questions: ISurveyQuestion[]) {
    questions.forEach((question, index) => {
      const isRequired = question.isRequired && !question.parentQuestionId;
      const control = new FormControl(
        '',
        isRequired ? Validators.required : null
      );
      this.questionsField.push(control);
      if (question.questionTypeId === 3) { // Textarea wait fir 1 second to save to server
        control.valueChanges
          .pipe(debounceTime(1000), distinctUntilChanged())
          .subscribe((value) => {
            this.controlValueChangeHandler(value, question);
          });
      } else if (question.questionTypeId !== 3 ) {
        control.valueChanges
          .pipe(distinctUntilChanged())
          .subscribe((value) => {
            this.controlValueChangeHandler(value, question);
          });
      }
    });
  }

  
  addsubQuestionsToForm(subQuestions: ISurveyQuestion[]) {
    subQuestions.forEach((question, index) => {
      const isRequired = question.isRequired && !question.parentQuestionId;
      const control = new FormControl(
        '',
        isRequired ? Validators.required : null
      );
      this.subQuestionsField.push(control);
      control.valueChanges
        //.pipe(debounceTime(1000), distinctUntilChanged())
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
        });
    });
  }

  controlValueChangeHandler(value, question) {
    this.surveyService
      .saveSurveyAnswer(question, value)
      .subscribe(() => {
        if (
          this.controller.isLast() &&
          (question.questionTypeId === 1 || question.questionTypeId === 4) &&
          this.questionsField.controls[
            this.questionsField.controls.length - 1
          ].value
        ) {
          this.submitSurvey();
        }
      });
  }

  get totalAnswered() {
    let totalAnswered = 0;
    this.restOfQuestions.forEach((question, index) => {
      const ctrl = this.questionsField.controls[index];
      //For each main question
      if (ctrl &&
        !question.parentQuestionId &&
        (question.isRequired
          ? ctrl.value
          : ctrl.value || this.controller.index > index)
      ) {
        totalAnswered++;
      }

      if (ctrl &&
        question.questionTypeId === 5 && this.allSubQuestionAnswered == true
      ) { //If matrix all subquestion answered
        totalAnswered++;
      }
    });
    return totalAnswered;
  }

  submitSurvey = () => {
    this.loading = true;
    this.surveyService.setSurveyComplate().subscribe(() => {
      this.questions = [];
      this.loading = false;
    });
  }
}
