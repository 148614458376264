import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';
import { ISurveyQuestion } from '../survey.service';


export interface QuestionsLoopContext {
  $implicit: ISurveyQuestion;
  controller?: {
    next: () => void;
    prev: () => void;
    isLast: () => void;
    isFirst: () => void;
    index: number;
  };
  index: number;
}

@Directive({
  selector: '[c2QuestionsLoop]',
})
export class QuestionsLoopDirective implements OnChanges {
  @Input() c2QuestionsLoopOf: ISurveyQuestion[] = [];
  @Input() c2QuestionsLoopForm: FormGroup;

  @Input() c2QuestionsLoopSubmit: Function;
  context: QuestionsLoopContext | null = null;
  index = 0;
  pages = [0];

  constructor(
    private tmpl: TemplateRef<QuestionsLoopContext>,
    private vcr: ViewContainerRef
  ) {}

  render(item = 0) {
    if (environment.phase === 1) {
      this.c2QuestionsLoopOf.forEach((question, index) => {
        this.context = {
          $implicit: this.c2QuestionsLoopOf[index],
          index: index,
        };
        this.vcr.createEmbeddedView(this.tmpl, this.context);

      });
      return;
    }
    this.context = {
      $implicit: this.c2QuestionsLoopOf[item],
      controller: {
        next: this.next.bind(this),
        prev: this.prev.bind(this),
        isLast: this.isLast.bind(this),
        isFirst: this.isFirst.bind(this),
        index: item,
      },
      index: item,
    };
    this.vcr.createEmbeddedView(this.tmpl, this.context);
  }

  ngOnChanges(changes) {
    if (
      changes.c2QuestionsLoopOf &&
      changes.c2QuestionsLoopOf.currentValue &&
      changes.c2QuestionsLoopOf.currentValue !==
        changes.c2QuestionsLoopOf.previousValue &&
      changes.c2QuestionsLoopOf.currentValue.length > 0
    ) {
      this.render();
    }
  }

  next() {
    if (this.isLast()) {
      this.c2QuestionsLoopSubmit();
      return;
    }
    const option = this.c2QuestionsLoopForm.value.questions[this.index];

    const optionSelected = this.c2QuestionsLoopOf[this.index].questionOptions.find(op => op.id === option);
    if (optionSelected && optionSelected.nextQuestionId === 0) { // 0 is generic question to indicate no more questions
      this.c2QuestionsLoopSubmit();
      return;
    }
    const nextQuestionId = (optionSelected && optionSelected.nextQuestionId) || this.c2QuestionsLoopOf[this.index].nextQuestionId;

    if (nextQuestionId) {
      const nextQuestion = this.c2QuestionsLoopOf.find(question => question.id === nextQuestionId);
      this.index = this.c2QuestionsLoopOf.indexOf(nextQuestion);
    } else {
      this.index++;
      if (this.index >= this.c2QuestionsLoopOf.length) {
        this.index = 0;
      }
    }
    this.pages.push(this.index);
    this.context.$implicit = this.c2QuestionsLoopOf[this.index];
    this.context.controller.index = this.index;
    this.context.index = this.index;
  }

  prev() {
    this.pages.pop();
    this.index = this.pages[this.pages.length - 1];

    this.context.$implicit = this.c2QuestionsLoopOf[this.index];
    this.context.controller.index = this.index;
    this.context.index = this.index;
  }

  isLast() {
    return (this.index + 1) === this.c2QuestionsLoopOf.length;
  }

  isFirst() {
    return this.index === 0;
  }
}
