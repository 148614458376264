<footer class="slideInUp">
  <div class="footer-wrapper">
    <div class="left">
      <p>{{totalAnswered}} of {{total}} answered</p>
      <c2-progress [width]="(totalAnswered/total)* 100"></c2-progress>
    </div>
    <button class="primary" (click)="controller.next()" [disabled]="nextDisabled">
      <ng-container *ngIf="!controller.isLast()">NEXT</ng-container>
      <ng-container *ngIf="controller.isLast()">SUBMIT</ng-container>
    </button>
    <button (click)="controller.prev()" *ngIf="!controller.isFirst()">
      PREV
    </button>
  </div>
</footer>
