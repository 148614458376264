import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISurveyQuestion } from '../survey.service';

@Component({
  selector: 'c2-survey-footer',
  templateUrl: './survey-footer.component.html',
  styleUrls: ['./survey-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyFooterComponent {
  @Input() controller;
  @Input() total;
  @Input() totalAnswered;
  @Input() currentQuestion: ISurveyQuestion;
  @Input() currentQuestionIndex;
  @Input() form;
  @Input() nextDisabled;
  constructor() {}

}
