import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { QuestionComponent } from './question/question.component';
import { SharedModule } from './shared/shared.module';
import { SurveyFooterComponent } from './survey-footer/survey-footer.component';
import { SurveyService } from './survey.service';

@NgModule({
  declarations: [AppComponent, SurveyFooterComponent, QuestionComponent],
  imports: [BrowserModule, SharedModule, HttpClientModule, ReactiveFormsModule],
  providers: [SurveyService],
  bootstrap: [AppComponent],
})
export class AppModule {}
