<header class="slideInDown" [class.phase-2]="phase === 2">
 <img src="../assets/C2smarter.png" alt="C2 Education Logo"/>
</header>
<main>
  <c2-spinner *ngIf="loading"></c2-spinner>
  <form [formGroup]="surveyForm" *ngIf="!loading">
    <div *ngIf="!questions.length" class="confirmation">
      <ng-container *ngIf="!warning; else warningMessage">
        <img src="./../assets/confirmation.svg" alt="Confirmation">
        <h1>Thank You!</h1>
        <h2>
          Your survey has <span *ngIf="locked">already</span> been submitted.
          <br>We appreciate your feedback!
        </h2>
        <h3>
          <br><a href="https://www.c2educate.com/referral-program/?utm_source=nps&utm_medium=email&utm_campaign=npsrefer">Refer your friends to C2</a> and get 10 free bonus hours! Learn more <a href="https://www.c2educate.com/referral-program/?utm_source=nps&utm_medium=email&utm_campaign=npshere">here</a>.
        </h3>
      </ng-container>
      <ng-template #warningMessage>
        <img src="./../assets/warning.svg" alt="Confirmation">
        <h1>{{ error ? 'Error' : 'Expired!' }}</h1>
        <h3 *ngIf="error;else expired">The survey link is invalid <br> Please contact your Center Director.</h3>
        <ng-template #expired> <h3>Your survey invitation has been expired,<br> after 4 weeks</h3> </ng-template>
      </ng-template>
    </div>
    <ng-container formArrayName="questions">
      <div *ngIf="questions.length">
        <div *c2QuestionsLoop="let question of restOfQuestions form surveyForm submit submitSurvey; let i = index; let c = controller">
            
            <c2-question  [question]="question" [subQuestions]="subQuestions" [index]="i" [form]="surveyForm" (handleRadioChange)="onRadioChange()"
            (handelSubQuestionAnswered)="subQuestionAnswered($event)"></c2-question>
            
            <button type="button" 
            *ngIf="(questions.length && (question.questionTypeId === 3 || question.questionTypeId === 2 || question.questionTypeId === 5) && phase === 2 && !controller.isLast())" 
            class="btn-submit" [disabled]="nextDisabled" (click)="controller.next()">Continue</button>

            {{setController(c)}}
        </div>
      </div>
    </ng-container>
    <button type="button" *ngIf="questions.length && phase === 1 && controller.isLast()" class="btn-submit" [disabled]="surveyForm.invalid" (click)="submitSurvey()">Submit</button>
    <p style="margin-top: 14px;" *ngIf="phase === 1 && surveyForm.invalid">Please complete all required questions (marked with *) prior to submitting a survey.</p>
  </form>
</main>
<c2-survey-footer *ngIf="phase === 2 && questions.length" [controller]="controller" [nextDisabled]="nextDisabled" [totalAnswered]="totalAnswered" [total]="total"></c2-survey-footer>
