<p>
  <span *ngIf="question.number">{{index + 1 }}.</span> {{question.name}}
  <span *ngIf="phase === 1 ? question.isRequired && !question.parentQuestionId : question.isRequired" class="required-sign">*</span>
</p>
<form [formGroup]="form">
  <div class="options" *ngIf="(question.questionTypeId != 3 && question.questionTypeId != 5 && question.number != 0)">
    <div *ngFor="let option of question.questionOptions" formArrayName="questions">
      <input
        *ngIf="question.questionTypeId === 1"
        [formControlName]="index"
        type="radio"
        [id]="option.id"
        [value]="option.id"
        [attr.name]="index"
        (change)="onRadioChange()"
        class="radio"
      >
      <input
        *ngIf="question.questionTypeId === 4"
        [formControlName]="index"
        type="radio"
        [id]="option.id"
        [value]="option.id"
        [attr.name]="index"
        (change)="onRadioChange()"
        class="radio-classic"
      >
      <input
        *ngIf="question.questionTypeId === 2"
        type="checkbox"
        [id]="option.id"
        [value]="option.id"
        [checked]="shouldSelectCheckbox(option.id)"
        (change)="onCheckboxChange($event.target.checked, option)"
      >
      <label class="label" [ngClass]="{' label-classic': (question.questionTypeId === 4)}" 
        [attr.for]="option.id">{{option.name}}</label>
    </div>
  </div>

  <div class="options" *ngIf="(question.questionTypeId == 3 && question.number != 0)" formArrayName="questions">
    <textarea [formControl]="questionsField.controls[index]" 
      [attr.placeholder]="question.instruction" class="form-control" 
      [attr.name]="index" [id]="question.id" cols="30"
      rows="8"></textarea>
  </div>
  <div class="options" *ngIf="(question.questionTypeId == 5)">
    <div class="flex-box">
      <label class="label label-classic matrix-disp">&nbsp;</label>
      <div *ngFor="let option of question.questionOptions; index as i" class="w-16">
        <label class="label" [ngClass]="{' label-classic': (question.questionTypeId === 5), 'first-radio_label': (i===0 || i === 4), 'other-radio-label': (1 != 0 && i != 4)}" 
          [attr.for]="option.id">{{option.name}} 
        </label>
      </div>
    </div>

    <div *ngFor="let question of subQuestions">
      <div class="flex-box">
        <label class="label label-classic matrix-disp">{{question.name}}
          <span *ngIf="phase === 1 ? question.isRequired && !question.parentQuestionId : question.isRequired" class="required-sign">*</span>
        </label>
        <div *ngFor="let option of question.questionOptions"  class="w-16">
          <input
            *ngIf="question.questionTypeId === 4 && option.selected"
            type="radio"            
            [attr.name]="question.id"
            class="radio-classic"
            checked
          > 
          <input
            *ngIf="question.questionTypeId === 4 && !option.selected"
            type="radio"            
            [attr.name]="question.id"
            (change)="subQuestionRadioChange(question,option.id)"
            class="radio-classic"
          >
        </div>
      </div>
    </div>
  </div>

</form>
