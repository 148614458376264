import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ISurveyQuestion } from '../survey.service';
import { FormArray } from '@angular/forms';
import { environment } from '../../environments/environment';

@Component({
  selector: 'c2-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
  @Input() question: ISurveyQuestion;
  @Input() index: number;
  @Input() form: FormArray;
  @Input() subQuestions: [ISurveyQuestion];

  public loading;
  phase = environment.phase;
  private selectionMap = {};
  public subQuestionselections = {};
  
  @Output() handleRadioChange = new EventEmitter<any>();
  @Output() handelSubQuestionAnswered = new EventEmitter<any>();

  private getCheckboxSelectionMap() {
    return this.selectionMap[this.question.id] || {};
  }

  private setCheckboxSelectionMap(updatedCheckboxSelections) {
    this.selectionMap[this.question.id] = updatedCheckboxSelections;
  }

  shouldSelectCheckbox(optionId: number) {
    const existingCheckboxSelectionMap = this.getCheckboxSelectionMap();
    return !!existingCheckboxSelectionMap[optionId];
  }

  get questionsField(): FormArray {
    return <FormArray>this.form.get('questions');
  }

  onRadioChange() {
    this.handleRadioChange.next(null);
  }
  
  subQuestionRadioChange(question,selectedOptionId) {
    this.subQuestionselections[question.id] = selectedOptionId;
    const selectedOption = {
      "questionId": question.id,
      "selectedOptionId" : selectedOptionId,
      "all": this.subQuestionselections,
      "mainQuestion": this.question
    }
    this.subQuestions.forEach(subquestion => {
      if(subquestion.id === question.id){
        subquestion.questionOptions.forEach(option => {
          if(option.id === selectedOptionId){
            option.selected = true;
          } else {
            option.selected = false;
          }
        })
      }
    });
    this.handelSubQuestionAnswered.next(selectedOption);
    
  }

  onCheckboxChange(isChecked: boolean, option: any) {
    const questionsArray = <FormArray>this.form.get('questions');
    let isOtherOptionsSelected = false;
    // store all selections (including current selection) in the class property..
    const selectionsMap = {
      ...this.getCheckboxSelectionMap(),
      [option.id]: isChecked,
    };
    this.setCheckboxSelectionMap(selectionsMap);
    // computing below to see if atleast one option is selected or not..
    const arr = Object.values(selectionsMap);
    if (arr.length) {
      isOtherOptionsSelected = arr.some((item) => !!item);
    }
    // Set current selection in the form control..
    questionsArray.controls[this.index].setValue({
      [option.id]: isChecked,
      isOtherOptionsSelected,
    });
  }
}
